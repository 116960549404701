// src/components/Layout.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Adjust breakpoint for better responsiveness

  const navLinks = (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        mt: 1,
        alignItems: "center",
        flexGrow: 1,
        justifyContent: "flex-end",
      }}
    >
      {/* Buy Me a Coffee Image Button */}
      <a
        href="https://www.buymeacoffee.com/flybnb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
          style={{
            height: isMobile ? "40px" : "55px", // Smaller height on mobile
            width: isMobile ? "150px" : "200px", // Smaller width on mobile
          }}
        />
      </a>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Header / Navbar */}
      <AppBar position="static" sx={{ backgroundColor: "#121212" }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* Logo on the left */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                mt: 2,
                mb: 2,
              }}
            >
              <img
                src="./new-logo.svg"
                alt="FlyBnB Logo"
                style={{
                  height: isMobile ? "50px" : "60px",
                  cursor: "pointer",
                }}
                onClick={() => (window.location.href = "/")}
              />
            </Box>
            {navLinks}
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          backgroundColor: "#5F5AA2",
          color: "#fff",
          textAlign: "center",
          py: isMobile ? 0.3 : 1, // Adjust padding for mobile
          px: isMobile ? 2 : 2,
          fontSize: isMobile ? "0.9rem" : "1rem", // Smaller font size on mobile
          fontWeight: "bold",
        }}
      >
        🚀 Just launched, might have bugs –{" "}
        <span
          onClick={() => (window.location.href = "/#contact")}
          style={{
            color: "#fff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          reach out with feedback below!
        </span>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flex: 1,
          p: 0,
          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Outlet />
        {/* "How does it work?" Section */}
      </Box>

      {/* "How does it work?" Section */}
      {/* Footer */}
      <Box
        component="footer"
        sx={{
          backgroundColor: "#121212",
          textAlign: "center",
          py: 3,
          mt: "auto",
          borderTop: "1px solid #333",
          color: "#fff",
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ maxWidth: "600px", mx: "auto", mb: 1, ml: 2, mr: 2 }}
        >
          DISCLAIMER: I'm not affiliated with any booking website and do not
          intend to represent any. This is a passion project developed
          independently. Use at your own discretion. Your feedback is welcome!
        </Typography>
      </Box>
    </Box>
  );
};

export default Layout;
