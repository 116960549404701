// src/components/HowItWorks.js

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  Alert,
} from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isDisabled] = useState(false); // Toggle to enable/disable API

  // Formspree integration

  const [state, handleSubmit] = useForm("xanyovgy");

  // Styles for the icons
  const iconStyles = {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    marginBottom: "16px",
  };

  // Styles for the container
  const containerStyles = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center", // Center items vertically
    gap: 2,
    mt: 5,
    maxWidth: "1200px",
    width: "100%",
    mx: "auto",
    px: 2,
  };

  // Styles for each entry
  const entryStyles = {
    flex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  // Styles for the symbols
  const symbolStyles = {
    fontSize: "48px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    mx: isMobile ? 0 : 1,
    my: isMobile ? 2 : 0,
  };

  return (
    <Box sx={{ py: 3, px: 5, backgroundColor: "#272727" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "800",
          mb: 0,
          fontFamily: "'Roboto', sans-serif",
          textAlign: "center",
          color: "white",
        }}
      >
        How Does It Work?
      </Typography>

      <Box sx={containerStyles}>
        {/* Flight Entry */}
        <Box sx={entryStyles}>
          <img
            src="/flight-icon.svg" // Replace with your flight icon path
            alt="Flight Icon"
            style={iconStyles}
          />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Flights
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Get flight options & prices for your selected dates and
              destinations. Quick filters for best value, cheapest, or fastest
              flight options, including flight itinerary.
            </Typography>
          </Box>
        </Box>

        {/* Plus Sign */}
        <Typography sx={symbolStyles}>+</Typography>

        {/* Stay Entry */}
        <Box sx={entryStyles}>
          <img
            src="/stay-icon.svg" // Replace with your stay icon path
            alt="Stay Icon"
            style={iconStyles}
          />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Stays
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Find and compare Airbnb stays with average prices for economy,
              standard, and premium listings at a glance. Filter for reviews and
              stay type.
            </Typography>
          </Box>
        </Box>

        {/* Equals Sign */}
        <Typography sx={symbolStyles}>=</Typography>

        {/* FlyBnB Entry */}
        <Box sx={entryStyles}>
          <img
            src="/both-icon.svg" // Replace with your FlyBnB logo path
            alt="FlyBnB Icon"
            style={{
              ...iconStyles,
              width: "90px", // Larger width for FlyBnB logo
              height: "90px", // Larger height for FlyBnB logo
            }}
          />
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
            FlyBnB
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Combine flight and Airbnb stay searches to get the total cost of
              your trip instantly. Simplify your travel planning with FlyBnB.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Subscription Section */}
      <Box
        sx={{
          mt: 8,
          textAlign: "center",
          py: 0,
          px: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "800",
            mb: 2,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            color: "white",
          }}
        >
          Stay Updated!
        </Typography>
        <Typography
          variant="body1" // Match variant to "How It Works" body text
          sx={{ mb: 3, color: "text.secondary", maxWidth: "700px", mx: "auto" }}
        >
          Sign up to get updates on new features and improvements. I'm crafting
          a deals newsletter to deliver Flight + Airbnb offers from your home
          airport, straight to your inbox.
        </Typography>
        {!state.succeeded ? (
          <form
            onSubmit={(e) => {
              if (isDisabled) {
                e.preventDefault();
                alert("Subscription is currently disabled for debugging.");
                return;
              }
              handleSubmit(e);
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 2 : 1,
                mb: 3,
              }}
            >
              <TextField
                label="Enter your email"
                type="email"
                name="email"
                variant="outlined"
                size="small"
                error={!!state.errors?.find((err) => err.field === "email")}
                helperText={
                  state.errors?.find((err) => err.field === "email")?.message
                }
                sx={{
                  maxWidth: "300px",
                  width: "100%",
                  mb: isMobile ? 0 : 0,
                }}
                InputProps={{
                  sx: {
                    height: "45px", // Match height of contact form
                  },
                }}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={state.submitting}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  minHeight: "45px", // Match TextField height
                  padding: isMobile ? "8px 16px" : "0 24px", // Adjust padding based on screen size
                  width: isMobile ? "100%" : "auto",
                }}
              >
                Subscribe
              </Button>
            </Box>
          </form>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              mt: 4,
              maxWidth: "600px", // Set max width for the box
              mx: "auto", // Center the box horizontally
            }}
          >
            <Alert
              severity="success"
              sx={{
                mt: 3,
                mb: 3,
                fontSize: "1.25rem",
                fontWeight: "bold",
                textAlign: "center", // Center the text inside the Alert
              }}
            >
              You’re now subscribed. Great stuff awaits! 🎉
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HowItWorks;
