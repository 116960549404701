// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    primary: {
      main: "#5F5AA2", // Primary Color
      main2: "0E34A0", // Test Primary Color
      secondary: "#121212", // Secondary Color
      flight: "#07a1fc", //Flights Colors
      stay: "#FF5A5F", // Stay Colors
    },
  },
});

export default theme;
