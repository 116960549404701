// src/components/Home.js

import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchForm from "./SearchForm"; // Adjust path as needed
import CompactSearchForm from "./CompactSearchForm";
import Results from "./Results";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDebounce } from "use-debounce";
import rawAirportsData from "../airports.json"; // Adjust path as needed
import { styled } from "@mui/material/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import HowItWorks from "./HowItWorks";
import Contact from "./Contact";
import { Divider, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { darken } from "@mui/system";

const CustomPickersDayStyled = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "departureDate" && prop !== "key", // Exede 'key'
})(({ theme, departureDate, day }) => ({
  ...(departureDate &&
    day.getTime() === departureDate.getTime() && {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
}));

const Home = () => {
  // State variables
  const [originInput, setOriginInput] = useState("");
  const [destinationInput, setDestinationInput] = useState("");
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [flightResults, setFlightResults] = useState([]); // Default to empty array
  const [noFlightsFound, setNoFlightsFound] = useState(false); // New state
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("best");
  const [allItineraries, setAllItineraries] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false); // Add this new state
  const [selectedStayCategory, setSelectedStayCategory] = useState("standard"); // Default to 'standard'
  const [economyListings, setEconomyListings] = useState([]);
  const [standardListings, setStandardListings] = useState([]);
  const [premiumListings, setPremiumListings] = useState([]);
  const [flightType, setFlightType] = useState("roundtrip");
  const [airbnbType, setAirbnbType] = useState("both"); // Default value is 'both'
  const [submittedOrigin, setSubmittedOrigin] = useState(null);
  const [submittedDestination, setSubmittedDestination] = useState(null);
  const [submittedDepartureDate, setSubmittedDepartureDate] = useState(null);
  const [submittedReturnDate, setSubmittedReturnDate] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");

    if (scrollTo === "contact") {
      const contactSection = document.querySelector("#contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }

      // Clear the query parameter from URL without reloading the page
      const newUrl = window.location.pathname;
      window.history.replaceState(null, "", newUrl);
    }
  }, [location.search]);

  // Handle stay category change
  const handleStayCategoryChange = (event, newValue) => {
    if (newValue !== null) {
      setSelectedStayCategory(newValue);
    }
  };

  // Format date for SkyScanner URL
  const formatDateForSkyScanner = (date) => {
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };

  // Generate SkyScanner link
  const generateSkyScannerLink = useCallback(() => {
    if (!origin || !destination || !departureDate) return "#";

    const formattedDepartureDate = formatDateForSkyScanner(departureDate);
    const originIATA = origin.iata;
    const destinationIATA = destination.iata;

    let skyscannerUrl = `https://www.skyscanner.com/transport/flights/${originIATA}/${destinationIATA}/${formattedDepartureDate}`;

    // Add return date only if it's a roundtrip
    if (flightType === "roundtrip" && returnDate) {
      const formattedReturnDate = formatDateForSkyScanner(returnDate);
      skyscannerUrl += `/${formattedReturnDate}`;
    }

    skyscannerUrl += `/?adultsv2=${adults}&cabinclass=economy&childrenv2=&ref=home&rtn=${
      flightType === "roundtrip" ? 1 : 0
    }&preferdirects=false&outboundaltsenabled=true&inboundaltsenabled=true`;

    return skyscannerUrl;
  }, [origin, destination, departureDate, returnDate, adults, flightType]);

  // Debounce the input values to improve performance
  const [debouncedOriginInput] = useDebounce(originInput, 300);
  const [debouncedDestinationInput] = useDebounce(destinationInput, 300);

  // Filter and sort airports data
  const airportsData = useMemo(() => {
    return rawAirportsData.filter(
      (airport) =>
        airport.iata &&
        airport.iata.length === 3 &&
        airport.iata !== "\\N" &&
        airport.type !== "heliport" &&
        airport.type !== "closed" &&
        airport.type !== "seaplane_base" &&
        airport.type !== "balloonport"
    );
  }, []);

  const filterAirports = useCallback(
    (inputValue) => {
      if (!inputValue) return [];

      const searchStr = inputValue.toLowerCase();

      let exactIATAMatches = [];
      let iataStartsWithMatches = [];
      let cityMatches = [];

      // Exact IATA code match (only when input is exactly 3 characters)
      if (inputValue.length === 3) {
        exactIATAMatches = airportsData
          .filter((airport) => airport.iata?.toLowerCase() === searchStr)
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // Starts-with IATA code matches (only for inputs of length 1-2)
      if (inputValue.length < 3) {
        iataStartsWithMatches = airportsData
          .filter((airport) =>
            airport.iata?.toLowerCase().startsWith(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // City name matches
      if (inputValue.length > 3) {
        // For input longer than 3 characters, match city names that **start with** the search string
        cityMatches = airportsData
          .filter((airport) =>
            airport.city?.toLowerCase().startsWith(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      } else {
        // For input of 3 or fewer characters, match city or country names that **include** the search string
        cityMatches = airportsData
          .filter(
            (airport) =>
              airport.city?.toLowerCase().includes(searchStr) ||
              airport.country?.toLowerCase().includes(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // Combine all matches
      const allMatches = [
        ...exactIATAMatches,
        ...iataStartsWithMatches,
        ...cityMatches,
      ];

      // Remove duplicates per city by using city and country as the key
      const uniqueMatches = Object.values(
        allMatches.reduce((acc, curr) => {
          const key = `${curr.city}-${curr.country}`;
          if (!acc[key]) {
            acc[key] = curr;
          }
          return acc;
        }, {})
      );

      // Sorting logic
      const sortedMatches = uniqueMatches.sort((a, b) => {
        const aCity = a.city.toLowerCase();
        const bCity = b.city.toLowerCase();

        const aStartsWithSearch = aCity.startsWith(searchStr);
        const bStartsWithSearch = bCity.startsWith(searchStr);

        // Prioritize cities that start with the search string
        if (aStartsWithSearch && !bStartsWithSearch) return -1;
        if (!aStartsWithSearch && bStartsWithSearch) return 1;

        // Sort alphabetically by city name
        const cityComparison = aCity.localeCompare(bCity);
        if (cityComparison !== 0) return cityComparison;

        // If city names are the same, sort alphabetically by country
        return a.country.localeCompare(b.country);
      });

      return sortedMatches;
    },
    [airportsData]
  );

  // Filtered options for Origin Autocomplete
  const originOptions = useMemo(
    () => filterAirports(debouncedOriginInput),
    [debouncedOriginInput, filterAirports]
  );

  // Filtered options for Destination Autocomplete
  const destinationOptions = useMemo(
    () => filterAirports(debouncedDestinationInput),
    [debouncedDestinationInput, filterAirports]
  );

  // Cache entity IDs in localStorage with key `entityIdsCache`
  const loadEntityIdsCache = () =>
    JSON.parse(localStorage.getItem("entityIdsCache")) || {};
  const saveEntityIdsCache = (cache) =>
    localStorage.setItem("entityIdsCache", JSON.stringify(cache));

  // Function to get Entity ID
  const getEntityId = useCallback(async (airports) => {
    const entityIdsCache = loadEntityIdsCache(); // Load cached entity IDs

    for (const airport of airports) {
      const { iata } = airport; // Extract the IATA code

      // Check if the entity ID exists in the cache
      if (entityIdsCache[iata]) {
        console.log(
          `Entity ID for ${iata} found in cache: ${entityIdsCache[iata]}`
        );
        return entityIdsCache[iata]; // Return cached ID if found
      }

      console.log(
        `Entity ID for ${iata} not found in cache. Fetching from API...`
      );

      const options = {
        method: "GET",
        url: "https://sky-scrapper.p.rapidapi.com/api/v1/flights/searchAirport",
        params: { query: iata, locale: "en-US" },
        headers: {
          "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
          "x-rapidapi-host": process.env.REACT_APP_RAPIDAPI_HOST,
        },
      };

      try {
        const response = await axios.request(options);
        const entityId = response.data?.data?.[0]?.entityId || null;

        if (entityId) {
          console.log(`Fetched Entity ID for ${iata} from API: ${entityId}`);

          // Cache the new entity ID
          entityIdsCache[iata] = entityId;
          saveEntityIdsCache(entityIdsCache); // Save the updated cache

          return entityId; // Return the valid entity ID
        } else {
          console.error(`No entity ID found in API response for ${iata}.`);
        }
      } catch (error) {
        console.error(`Error fetching entity ID for ${iata}:`, error);
      }
    }

    console.error("No valid entity ID found for any airport in the city.");
    return null; // Return null if no valid ID found for any airport
  }, []); // No dependencies needed for this function

  const handleClearStayFilter = () => {
    setAirbnbType("both");
  };

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to fetch Airbnb stays, memoized with useCallback
  const fetchAirbnbStays = useCallback(
    async (destination) => {
      const location = `${destination.city}, ${destination.country}`;
      const checkinDate = formatLocalDate(departureDate);
      const checkoutDate = formatLocalDate(returnDate);
      const pageCount = airbnbType === "both" ? 1 : 2;

      const fetchPage = async (page) => {
        const options = {
          method: "GET",
          url: "https://airbnb13.p.rapidapi.com/search-location",
          params: {
            location,
            checkin: checkinDate,
            checkout: checkoutDate,
            adults: String(adults),
            children: "0",
            infants: "0",
            pets: "0",
            page: String(page),
            currency: "USD",
          },
          headers: {
            "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
            "x-rapidapi-host": "airbnb13.p.rapidapi.com",
          },
        };

        try {
          const response = await axios.request(options);
          console.log(`Airbnb Response for page ${page}:`, response.data);
          return response.data.results || []; // Return the results directly
        } catch (error) {
          console.error(`Error fetching Airbnb stays for page ${page}:`, error);
          return []; // Return empty array if there's an error
        }
      };

      // Fetch results from the specified number of pages
      const promises = Array.from({ length: pageCount }, (_, index) =>
        fetchPage(index + 1)
      );
      const allResults = (await Promise.all(promises)).flat();

      return allResults;
    },
    [departureDate, returnDate, adults, airbnbType]
  );

  const normalizeListingPrices = (listings, nights) => {
    return listings.map((listing) => {
      if (listing.price.total === null && listing.price.rate) {
        // If 'total' is null and 'rate' exists, assume it's a monthly rate
        const estimatedNightlyRate = listing.price.rate / 30;
        listing.price.total = estimatedNightlyRate * nights; // Calculate based on stay duration
      }
      return listing;
    });
  };

  // Calculate Percentile Groups and their Medians
  const calculateQuartiles = useCallback((numbers) => {
    const validNumbers = numbers.filter((price) => price !== null); // Filter out null prices
    if (validNumbers.length === 0) return { Q1: null, Q2: null, Q3: null };

    const sorted = numbers.slice().sort((a, b) => a - b);

    const getMedian = (array) => {
      const mid = Math.floor(array.length / 2);
      return array.length % 2 !== 0
        ? array[mid]
        : (array[mid - 1] + array[mid]) / 2;
    };

    const size = Math.ceil(sorted.length / 3); // Size of each group

    // Split the sorted numbers into three groups
    const Q1Group = sorted.slice(0, size); // 0-33%
    const Q2Group = sorted.slice(size, size * 2); // 34-66%
    const Q3Group = sorted.slice(size * 2); // 67-100%

    return {
      Q1: getMedian(Q1Group),
      Q2: getMedian(Q2Group),
      Q3: getMedian(Q3Group),
      Q1Group,
      Q2Group,
      Q3Group,
    };
  }, []);

  // Classify Listings Based on Groups
  const classifyAndSortListingsByPrice = useCallback((listings, quartiles) => {
    if (listings.length < 6) {
      // If less than 6 listings, assign all to "standard" category
      return {
        economyListings: [],
        standardListings: listings,
        premiumListings: [],
      };
    }
    const { Q1Group, Q2Group, Q3Group } = quartiles;

    const sortByReviews = (a, b) => b.reviewsCount - a.reviewsCount;

    const economyListings = listings
      .filter((listing) => Q1Group.includes(listing.price.total))
      .sort(sortByReviews);

    const standardListings = listings
      .filter((listing) => Q2Group.includes(listing.price.total))
      .sort(sortByReviews);

    const premiumListings = listings
      .filter((listing) => Q3Group.includes(listing.price.total))
      .sort(sortByReviews);

    return { economyListings, standardListings, premiumListings };
  }, []);

  // Quartiles state
  const [quartiles, setQuartiles] = useState({ Q1: null, Q2: null, Q3: null });

  // Memoized selection functions

  const selectBestItinerary = useCallback(
    (allItineraries, fastestItinerary) => {
      if (!Array.isArray(allItineraries) || allItineraries.length === 0)
        return null;

      const initialBest = allItineraries.reduce(
        (best, current) =>
          current.score > best.score ||
          (current.score === best.score && current.price.raw < best.price.raw)
            ? current
            : best,
        allItineraries[0]
      );

      // Check if fastestItinerary is cheaper and return it as best if true
      if (
        fastestItinerary &&
        fastestItinerary.price.raw < initialBest.price.raw
      ) {
        return fastestItinerary;
      }

      return initialBest;
    },
    []
  );

  const selectCheapestItinerary = useCallback((allItineraries) => {
    if (allItineraries.length === 0) return null;

    const selected = allItineraries.reduce(
      (cheapest, current) =>
        current.price.raw < cheapest.price.raw ? current : cheapest,
      allItineraries[0]
    );
    return selected;
  }, []);

  const selectFastestItinerary = useCallback((allItineraries) => {
    const taggedItineraries = allItineraries.filter((it) =>
      it.tags?.includes("shortest")
    );

    if (taggedItineraries.length === 0 && allItineraries.length > 0) {
      console.warn("No itineraries with the 'shortest' tag were found.");
    }

    const selected =
      taggedItineraries.length > 0
        ? taggedItineraries.reduce(
            (cheapest, current) =>
              current.price.raw < cheapest.price.raw ? current : cheapest,
            taggedItineraries[0]
          )
        : allItineraries.reduce(
            (fastest, current) =>
              current.durationInMinutes < fastest.durationInMinutes
                ? current
                : fastest,
            allItineraries[0]
          );

    return selected;
  }, []);
  const fastestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectFastestItinerary(allItineraries)
        : null,
    [selectFastestItinerary, allItineraries]
  );

  // Define the itineraries based on the current state
  const bestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectBestItinerary(allItineraries, fastestItinerary)
        : null,
    [selectBestItinerary, allItineraries, fastestItinerary]
  );

  const cheapestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectCheapestItinerary(allItineraries)
        : null,
    [selectCheapestItinerary, allItineraries]
  );

  // Handle sort change
  const handleSortChange = useCallback(
    (newSortBy) => {
      setSortBy(newSortBy);

      let selectedItinerary;
      if (newSortBy === "cheap") {
        selectedItinerary = selectCheapestItinerary(allItineraries);
      } else if (newSortBy === "fastest") {
        selectedItinerary = selectFastestItinerary(allItineraries);
      } else {
        selectedItinerary = selectBestItinerary(allItineraries); // Pass the whole array
      }

      setFlightResults(selectedItinerary ? [selectedItinerary] : []);
    },
    [
      selectBestItinerary,
      selectCheapestItinerary,
      selectFastestItinerary,
      allItineraries,
    ]
  );
  // Handling the Search
  // Handling the Search
  const handleSearch = useCallback(async () => {
    if (!origin || !destination || !departureDate) {
      console.error("Please provide all required fields.");
      return;
    }
    console.time("Search Duration"); // Start timer
    const startTime = performance.now(); // Alternative timer for duration

    const formattedDepartureDate = departureDate.toLocaleDateString("en-CA");
    const formattedReturnDate = returnDate
      ? returnDate.toLocaleDateString("en-CA")
      : undefined;

    setLoading(true);
    setSearchInitiated(true);
    setNoFlightsFound(false);
    setFlightResults([]); // Clear previous flight results
    setAllItineraries([]); // Clear previous itineraries
    setEconomyListings([]); // Clear previous Airbnb economy listings
    setStandardListings([]); // Clear previous Airbnb standard listings
    setPremiumListings([]); // Clear previous Airbnb premium listings
    setSubmittedOrigin(origin);
    setSubmittedDestination(destination);
    setSubmittedDepartureDate(departureDate);
    setSubmittedReturnDate(returnDate);

    setTimeout(() => {
      setLoading(false); // Switch to skeleton after 2 seconds
    }, 4000);

    try {
      const [originEntityId, destinationEntityId] = await Promise.all([
        getEntityId(
          airportsData.filter(
            (airport) =>
              airport.city === origin.city && airport.country === origin.country
          )
        ),
        getEntityId(
          airportsData.filter(
            (airport) =>
              airport.city === destination.city &&
              airport.country === destination.country
          )
        ),
      ]);

      if (!originEntityId || !destinationEntityId) {
        console.error("Unable to fetch entity IDs.");
        setNoFlightsFound(true);
        return;
      }

      const searchOptions = {
        method: "GET",
        url: "https://sky-scrapper.p.rapidapi.com/api/v2/flights/searchFlights",
        //url: "https://sky-scrapper.p.rapidapi.com/api/v2/flights/searchFlightsWebComplete",
        params: {
          originSkyId: origin.iata,
          destinationSkyId: destination.iata,
          originEntityId,
          destinationEntityId,
          date: formattedDepartureDate,
          ...(flightType === "roundtrip" && {
            returnDate: formattedReturnDate,
          }),
          adults: String(adults),
          sortBy: "best",
          limit: 1000,
          currency: "USD",
          market: "en-US",
          countryCode: "US",
        },
        headers: {
          "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
          "x-rapidapi-host": process.env.REACT_APP_RAPIDAPI_HOST,
        },
      };

      const [firstResponse, airbnbResults] = await Promise.all([
        axios.request(searchOptions).catch((error) => {
          handleFlightError(error);
        }),
        fetchAirbnbStays(destination),
      ]);

      const firstItineraries = Array.isArray(
        firstResponse?.data?.data?.itineraries
      )
        ? firstResponse.data.data.itineraries
        : [];
      console.log("First Itineraries:", firstItineraries);

      let secondItineraries = [];
      let thirdItineraries = [];

      if (firstItineraries.length < 20) {
        try {
          const secondResponse = await axios.request(searchOptions);
          secondItineraries = Array.isArray(
            secondResponse?.data?.data?.itineraries
          )
            ? secondResponse.data.data.itineraries
            : [];
          console.log("Second Itineraries:", secondItineraries);

          if (secondItineraries.length < 20) {
            try {
              const thirdResponse = await axios.request(searchOptions);
              thirdItineraries = Array.isArray(
                thirdResponse?.data?.data?.itineraries
              )
                ? thirdResponse.data.data.itineraries
                : [];
              console.log("Third Itineraries:", thirdItineraries);
            } catch (error) {
              console.error("Error with third API call:", error);
            }
          }
        } catch (error) {
          console.error("Error with second API call:", error);
        }
      }

      const combinedItineraries = [
        ...firstItineraries,
        ...secondItineraries,
        ...thirdItineraries,
      ];

      if (combinedItineraries.length === 0) {
        setNoFlightsFound(true);
        return;
      }

      setAllItineraries(
        Array.isArray(combinedItineraries) ? combinedItineraries : []
      );

      const selectedItinerary =
        Array.isArray(combinedItineraries) && combinedItineraries.length > 0
          ? sortBy === "cheap"
            ? selectCheapestItinerary(combinedItineraries)
            : sortBy === "fastest"
              ? selectFastestItinerary(combinedItineraries)
              : selectBestItinerary(combinedItineraries) // Apply best itinerary logic here
          : null;

      setFlightResults(selectedItinerary ? [selectedItinerary] : []);

      const nights = Math.ceil(
        (returnDate - departureDate) / (1000 * 60 * 60 * 24)
      );

      const validAirbnbResults = normalizeListingPrices(
        airbnbResults.filter((result) => {
          const typeLowerCase = result.type.toLowerCase();
          const matchesType =
            airbnbType === "both" ||
            (airbnbType === "entire_home" &&
              typeLowerCase.includes("entire")) ||
            (airbnbType === "private_room" && typeLowerCase.includes("room"));

          return result.reviewsCount >= 5 && matchesType;
        }),
        nights
      );

      // Check for no results
      if (validAirbnbResults.length === 0) {
        console.warn("No matching Airbnb listings found.");
        setLoading(false);
        return (
          <Typography sx={{ mt: 5, textAlign: "center" }}>
            No matching Airbnb listings found for the selected type.
          </Typography>
        );
      }

      // Handle cases with one or two results
      let calculatedQuartiles;
      if (validAirbnbResults.length === 1) {
        // Only one result; set the same price for all quartiles
        const price = validAirbnbResults[0].price.total;
        calculatedQuartiles = { Q1: price, Q2: price, Q3: price };
      } else if (validAirbnbResults.length === 2) {
        // Two results; use the first and second prices directly
        calculatedQuartiles = {
          Q1: validAirbnbResults[0].price.total,
          Q2:
            (validAirbnbResults[0].price.total +
              validAirbnbResults[1].price.total) /
            2,
          Q3: validAirbnbResults[1].price.total,
        };
      } else {
        // Normal quartile calculation for three or more results
        const totalPrices = validAirbnbResults.map(
          (result) => result.price.total
        );
        calculatedQuartiles = calculateQuartiles(totalPrices);
      }

      // Set the calculated quartiles
      setQuartiles(calculatedQuartiles);

      const totalPrices = validAirbnbResults.map(
        (result) => result.price.total
      );
      const quartiles = calculateQuartiles(totalPrices);

      setQuartiles(quartiles);

      const { economyListings, standardListings, premiumListings } =
        classifyAndSortListingsByPrice(validAirbnbResults, quartiles);

      setEconomyListings(economyListings);
      setStandardListings(standardListings);
      setPremiumListings(premiumListings);
    } catch (error) {
      console.error("Error fetching flight results:", error);
    } finally {
      setLoading(false);
      const durationMs = performance.now() - startTime; // Calculate duration in ms
      const durationSec = (durationMs / 1000).toFixed(2); // Convert to seconds
      console.log(`Search Duration: ${durationSec} seconds`);
    }
  }, [
    origin,
    destination,
    departureDate,
    returnDate,
    adults,
    sortBy,
    getEntityId,
    fetchAirbnbStays,
    selectCheapestItinerary,
    selectFastestItinerary,
    selectBestItinerary,
    flightType,
    airportsData,
    calculateQuartiles,
    classifyAndSortListingsByPrice,
    airbnbType,
  ]);

  const handleFlightError = (error) => {
    if (error.response?.status === 502) {
      console.error("Bad Gateway: The server is temporarily unavailable.");
    } else if (error.response?.status === 404) {
      console.warn("No flights found.");
    } else {
      console.error("An unexpected error occurred:", error);
    }
    setNoFlightsFound(true);
  };

  // Empty useEffect (can be removed if not needed)
  useEffect(() => {}, [economyListings, standardListings, premiumListings]);

  // Handle back to search
  const handleBackToSearch = (callback) => {
    setFlightResults(null);
    setLoading(false); // Reset loading state
    setSearchInitiated(false); // Return to the search form view
    setNoFlightsFound(false); // Reset no flights state

    if (origin) {
      setOriginInput(`${origin.name} (${origin.iata})`);
    }
    if (destination) {
      setDestinationInput(`${destination.name} (${destination.iata})`);
    }

    // Safely invoke the callback if it exists
    if (typeof callback === "function") {
      callback();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
        maxWidth="sm"
        sx={{
          textAlign: "center",
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start", // Change from "center" to "flex-start"
          alignItems: "center", // Add this to center horizontally
          color: "text.primary",
          mt: 3,
          mb: 5, // Add some bottom margin for spacing
          px: 2, // Ensure padding doesn't cause overflow
        }}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={80} thickness={4} color="primary" />
            <Typography
              variant="h4"
              sx={{
                mt: 5,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Searching skies and homes....
            </Typography>
          </Box>
        )}
        {noFlightsFound ? (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
              No flights were found between these cities. <br />
              Please choose another pair.
            </Typography>
          </Box>
        ) : (
          // The rest of your normal UI components go here...
          <Box>{/* Flight and Stay Results */}</Box>
        )}
        {!loading && searchInitiated && (
          <Box>
            <CompactSearchForm
              origin={origin}
              setOrigin={setOrigin}
              originInput={originInput}
              setOriginInput={setOriginInput}
              originOptions={originOptions}
              destination={destination}
              setDestination={setDestination}
              destinationInput={destinationInput}
              setDestinationInput={setDestinationInput}
              destinationOptions={destinationOptions}
              departureDate={departureDate}
              setDepartureDate={setDepartureDate}
              returnDate={returnDate}
              setReturnDate={setReturnDate}
              adults={adults}
              setAdults={setAdults}
              handleSearch={handleSearch}
              flightType={flightType}
              handleBackToSearch={handleBackToSearch} // Add this line
            />

            <Typography
              variant="h5"
              sx={{
                fontWeight: "normal",
                mt: 4,
                mb: 0,
                fontSize: {
                  xs: "1.3rem", // Font size for extra-small screens
                  sm: "1.5rem", // Small screens
                },
                width: {
                  xs: "80%", // Width for extra-small screens
                  sm: "100%", // Small screens
                },
                mx: "auto", // Center the text horizontally
                textAlign: "center", // Center the text
              }}
            >
              {loading ? (
                <>
                  We're looking for your{" "}
                  <strong>{origin?.city || "departure"}</strong> to{" "}
                  <strong>{destination?.city || "destination"}</strong> travel
                  flights & stays for{" "}
                  <strong>
                    {returnDate && departureDate
                      ? Math.ceil(
                          (returnDate - departureDate) / (1000 * 60 * 60 * 24)
                        )
                      : "your selected"}
                  </strong>{" "}
                  days...
                </>
              ) : (
                (() => {
                  const stayPrice =
                    selectedStayCategory === "economy"
                      ? quartiles.Q1
                      : selectedStayCategory === "standard"
                        ? quartiles.Q2
                        : quartiles.Q3;

                  const flightPrice = flightResults?.[0]?.price?.formatted
                    ? Math.ceil(
                        parseFloat(
                          flightResults[0].price.formatted.replace(
                            /[^0-9.-]+/g,
                            ""
                          )
                        )
                      )
                    : NaN;

                  const totalPrice =
                    !isNaN(stayPrice) && !isNaN(flightPrice)
                      ? Math.ceil(stayPrice + flightPrice)
                      : NaN;

                  const days = Math.ceil(
                    (returnDate - departureDate) / (1000 * 60 * 60 * 24)
                  );

                  const totalStyle = {
                    fontWeight: "bold",
                    fontSize: "inherit",
                    color: "white", // Style for total price emphasis
                  };

                  return totalPrice && origin && destination ? (
                    <>
                      <strong>{submittedOrigin.city}</strong> to{" "}
                      <strong>{submittedDestination.city}</strong> for{" "}
                      <strong>{days}</strong> days with{" "}
                      <strong>{adults}</strong> adult
                      {adults > 1 ? "s" : ""} will cost a total of{" "}
                      <span style={totalStyle}>
                        ${totalPrice.toLocaleString()}.
                      </span>
                      <br />
                      The <strong>{sortBy}</strong> flight is{" "}
                      <strong>${flightPrice.toLocaleString()}</strong> and{" "}
                      <strong>{selectedStayCategory}</strong> stays average{" "}
                      <strong>${Math.ceil(stayPrice).toLocaleString()}</strong>.
                    </>
                  ) : (
                    <>
                      We're looking for your{" "}
                      <strong>{submittedOrigin?.city || "departure"}</strong> to{" "}
                      <strong>
                        {submittedDestination?.city || "destination"}
                      </strong>{" "}
                      travel flights & stays for{" "}
                      <strong>
                        {submittedReturnDate && submittedDepartureDate
                          ? Math.ceil(
                              (submittedReturnDate - submittedDepartureDate) /
                                (1000 * 60 * 60 * 24)
                            )
                          : "your selected"}
                      </strong>{" "}
                      days...
                    </>
                  );
                })()
              )}
            </Typography>

            {/* Total Results Cards Top */}
            <Box
              sx={{
                width: "100%", // Ensure it uses full width
                maxWidth: "1200px", // Prevent overflow on larger screens
                mx: "auto", // Center horizontally
                px: 2, // Add padding but ensure it's within bounds
                overflowX: "hidden", // Avoid horizontal scroll
              }}
            >
              <Results
                bestItinerary={bestItinerary}
                cheapestItinerary={cheapestItinerary}
                fastestItinerary={fastestItinerary}
                flightResults={flightResults}
                sortBy={sortBy}
                handleSortChange={handleSortChange}
                generateSkyScannerLink={generateSkyScannerLink}
                quartiles={quartiles}
                selectedStayCategory={selectedStayCategory}
                handleStayCategoryChange={handleStayCategoryChange}
                economyListings={economyListings}
                standardListings={standardListings}
                premiumListings={premiumListings}
                handleBackToSearch={handleBackToSearch}
                departureDate={departureDate}
                returnDate={returnDate}
                handleClearStayFilter={handleClearStayFilter}
                loading={loading}
              />
            </Box>
            <Button
              variant="contained"
              onClick={handleBackToSearch}
              sx={{
                width: 100,
                height: 50,
                mt: 3,
                backgroundColor: "#CBE11E",
                color: "black",
                fontSize: "1rem",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: (theme) => darken("#CBE11E", 0.2), // Darken by 20%
                },
              }}
            >
              Back
            </Button>
          </Box>
        )}

        {!loading && !searchInitiated && (
          <SearchForm
            originOptions={originOptions}
            originInput={originInput}
            setOriginInput={setOriginInput}
            origin={origin}
            setOrigin={setOrigin}
            destinationOptions={destinationOptions}
            destinationInput={destinationInput}
            setDestinationInput={setDestinationInput}
            destination={destination}
            setDestination={setDestination}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            returnDate={returnDate}
            setReturnDate={setReturnDate}
            adults={adults}
            setAdults={setAdults}
            handleSearch={handleSearch}
            filterAirports={filterAirports}
            CustomPickersDay={CustomPickersDayStyled}
            flightType={flightType} // Add this line
            setFlightType={setFlightType} // Add this line
            airbnbType={airbnbType}
            setAirbnbType={setAirbnbType}
          />
        )}
      </Container>
      {!loading && !searchInitiated && (
        <>
          {/* Add Divider here */}
          <Divider sx={{ my: 1, borderBottomWidth: 0, color: "#272727" }} />

          {/* HowItWorks Component */}
          <HowItWorks />
          <Divider sx={{ my: 1, borderBottomWidth: 0, color: "#272727" }} />
          <div
            id="contact"
            inert={!loading && !searchInitiated ? undefined : true}
          >
            <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
              <Contact />
            </Container>
          </div>
        </>
      )}{" "}
    </LocalizationProvider>
  );
};

export default Home;
